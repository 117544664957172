import { ProductModel } from '@shared/models/product.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IComment } from '@shared/models/comment-model';
import {
  IReviewReportReason,
  ISocialLike,
  SocialPostModel,
} from '@shared/models/social-post.model';
import { IUser, IUserShort } from '@models/user';
import { UserType } from '@shared/models/user.model';
import { map, of, tap } from 'rxjs';
import { ShareCigarModel } from '@shared/models/share-cigar.model';
import { ShareReviewModel } from '@shared/models/share-review.model';
import { UserReviewModel } from '@shared/models/user-review.model';
import { ICigarLog } from '@models';

@Injectable({
  providedIn: 'root',
})
export class SocialService {
  constructor(private http: HttpClient) {}

  searchUsers(
    searchTerm: string = '',
    skip: number = 0,
    take: number = environment.pageLimit || 20,
    friendlist = false
  ) {
    let params = new HttpParams();
    params = params.set('friendlist', friendlist.toString());
    params = params.set('Skip', skip.toString());
    params = params.set('Take', take.toString());
    return this.http.post<IUserShort[]>(
      'social/profiles/search',
      this.prepareStringAsPayload(searchTerm),
      {
        params,
        observe: 'response',
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      }
    );
  }

  private prepareStringAsPayload(text: string) {
    return `"${text.replace(/"/g, '\\"')}"`;
  }

  getUserPosts(userId: string, userType: UserType, skip: number) {
    let params = new HttpParams();
    params = params.set(
      userType === 'users' ? 'AuthorUserId' : 'AuthorUUID',
      userId
    );
    params = params.set('Skip', skip.toString());
    params = params.set('Take', environment.pageLimit.toString());
    return this.getPosts(params);
  }

  getProductPosts(productId: string, skip: number) {
    let params = new HttpParams();
    const productParts = productId.split('-');
    params = params.set(
      productParts[0] === 'L' ? 'LineId' : 'ProductId',
      productParts[1]
    );
    params = params.set('Skip', skip.toString());
    params = params.set('Take', environment.pageLimit.toString());
    return this.getPosts(params);
  }

  getSocialPosts(skip: number, followedOnly: boolean) {
    let params = new HttpParams();
    params = params.set('Skip', skip.toString());
    params = params.set('Take', environment.pageLimit.toString());
    params = params.set('TopComments', '2');
    params = params.set('FollowedOnly', followedOnly.toString());
    return this.getPosts(params);
  }

  getPosts(params: HttpParams) {
    return this.http.get<SocialPostModel[]>('social/posts', { params });
  }

  getPost(postId: number) {
    let params = new HttpParams();
    params = params.set('TopComments', '2');
    return this.http.get<SocialPostModel>(`social/posts/${postId}`, { params });
  }

  createPost(
    createPostData:
      | ShareCigarModel
      | ShareReviewModel
      | SocialPostModel
      | ProductModel
  ) {
    return this.http.post<SocialPostModel>('social/posts', createPostData);
  }

  updatePost(postId: number, post: SocialPostModel) {
    return this.http.put<SocialPostModel>(`social/posts/${postId}`, post);
  }

  removePost(postId: number) {
    return this.http.delete(`social/posts/${postId}`);
  }

  followToggle(userType: UserType, userId: string, isFollowed: boolean) {
    return isFollowed
      ? this.unfollowUser(userType, userId)
      : this.followUser(userType, userId);
  }

  getUser(userId: string, userType: UserType) {
    return this.http.get<IUser>(`social/${userType}/${userId}`);
  }

  followUser(userType: UserType, userId: string) {
    return this.http.post(`social/${userType}/${userId}/follow`, {});
  }

  unfollowUser(userType: UserType, userId: string) {
    return this.http.post(`social/${userType}/${userId}/unfollow`, {});
  }

  likeToggle(postId: number, isLiked: boolean) {
    return isLiked ? this.unlike(postId) : this.like(postId);
  }

  getLikes(postId: number, skip: number) {
    let params = new HttpParams();
    params = params.set('Skip', skip.toString());
    params = params.set('Take', environment.pageLimit.toString());
    return this.http.get<ISocialLike[]>(`social/posts/${postId}/likes`, {
      params: params,
      observe: 'response',
    });
  }

  like(postId: number) {
    return this.http.post<ISocialLike>(`social/posts/${postId}/like`, {});
  }

  unlike(postId: number) {
    return this.http.delete<null>(`social/posts/${postId}/like`);
  }

  getComments(postId: number) {
    return this.http.get<IComment[]>(
      `social/posts/${postId}/comments?take=9999`
    );
  }

  addComment(postId: number, Text: string) {
    return this.http.post<IComment>(`social/posts/${postId}/comments/`, {
      Text,
    });
  }

  editComment(postId: number, commentId: number, Text: string) {
    return this.http.put<IComment>(
      `social/posts/${postId}/comments/${commentId}`,
      { Text }
    );
  }

  removeComment(postId: number, commentId: number) {
    return this.http.delete(`social/posts/${postId}/comments/${commentId}`);
  }

  reportComment(commentId: number, reasonId: number) {
    return this.http.post(
      `social/report/comment/${commentId}/reason/${reasonId}`,
      {}
    );
  }

  reasons: IReviewReportReason[];
  reportReasons() {
    if (this.reasons) {
      return of(this.reasons);
    }
    return this.http
      .get<IReviewReportReason[]>(`social/report/reasons`)
      .pipe(tap((reasons) => (this.reasons = reasons)));
  }

  reportPost(postId: number, reasonId: number) {
    return this.http.post(
      `social/report/post/${postId}/reason/${reasonId}`,
      {}
    );
  }

  blockUser(userType: UserType, id: string) {
    return this.http.post(`social/${userType}/${id}/block`, {});
  }

  unblockUser(userType: UserType, id: string) {
    return this.http.post(`social/${userType}/${id}/unblock`, {});
  }

  reportUser(userType: UserType, userId: string, reasonId: number) {
    return this.http.post(
      `social/report/${userType}/${userId}/reason/${reasonId}`,
      {}
    );
  }

  getFollows(
    userId: string,
    userType: UserType,
    skip: number,
    type?: 'following' | 'followers'
  ) {
    let params = new HttpParams();
    params = params.set('Skip', skip.toString());
    params = params.set('Take', environment.pageLimit.toString());
    if (type === 'following') {
      params = params.set('FollowedOnly', 'true');
    } else if (type === 'followers') {
      params = params.set('FollowersOnly', 'true');
    }
    return this.http.get<IUserShort[]>(
      `social/${userType}/${userId}/profiles`,
      { params: params, observe: 'response' }
    );
  }

  searchReviews(
    userId: string,
    userType: UserType,
    filter: { Search?: string; dateFrom?: string; dateTill?: string } = {},
    skip: number
  ) {
    let params = new HttpParams();
    params = params.set('Skip', skip.toString());
    params = params.set('Take', environment.pageLimit.toString());

    return this.http.post<UserReviewModel[]>(
      `social/${userType}/${userId}/reviews`,
      filter,
      {
        params: params,
        observe: 'response',
      }
    );
  }

  getReviews(userId: string, userType: UserType, skip: number) {
    let params = new HttpParams();
    params = params.set('Skip', skip.toString());
    params = params.set('Take', environment.pageLimit.toString());

    return this.http.get<UserReviewModel[]>(
      `social/${userType}/${userId}/reviews`,
      {
        params: params,
        observe: 'response',
      }
    );
  }

  getCigarList(userId: string, list: string, userType: UserType, skip: number) {
    let params = new HttpParams();
    params = params.set('Skip', skip.toString());
    params = params.set('Take', environment.pageLimit.toString());
    return this.http.get<ICigarLog[]>(
      `social/${userType}/${userId}/cigarlogs/list/${list}`,
      {
        params,
      }
    );
  }
}
